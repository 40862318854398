import $ from 'jquery';

const Progress = (($, ns = '') => {
  $(document).ready(() => {
    const selectors = {
      PROGRESS: `.${ns}js-progress`,
      STEP: `.${ns}progress__step`,
      CONTENT: `.${ns}progress-content`
    };
    const classes = {
      STEP_ACTIVE: `${ns}progress__step--active`,
      CONTENT_ACTIVE: `${ns}progress-content--active`
    };

    $(selectors.PROGRESS).each((i, el) => {
      const $container = $(el);
      const $steps = $container.find(selectors.STEP);

      $steps.click(function () {
        const $el = $(this);
        const prog = $(this).data('toggle');

        $el.siblings().removeClass(classes.STEP_ACTIVE);
        $(selectors.CONTENT).removeClass(classes.CONTENT_ACTIVE);

        $el.addClass(classes.STEP_ACTIVE);
        $(`.${ns}progress-content[data-toggle=${prog}]`).addClass(classes.CONTENT_ACTIVE);
      });
    });
  });

  return Progress;
})($, window.wdkNamespace);

export default Progress;
