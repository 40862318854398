/* eslint-disable no-new */

import $ from 'jquery';
import Swiper from 'swiper';

const Slider = (($, ns = '') => {
  function initSwiper(el) {
    if (typeof Swiper === 'undefined') {
      throw new TypeError('Holmgrens\'s Slider requires Swiper.');
    }

    new Swiper(el, {
      loop: true,
      autoHeight: true,
      autoplay: false,
      navigation: {
        nextEl: `.${ns}modal--slider__next`,
        prevEl: `.${ns}modal--slider__prev`
      }
    });
  }

  $(document).ready(() => {
    $('.swiper-container').each(function () {
      initSwiper(this);
    });

    $(`.${ns}modal--slider`).one('shown.wdk.modal', function () {
      $(this).find('.swiper-container')[0].swiper.update();
    });
  });

  return Slider;
})($, window.wdkNamespace);

export default Slider;
