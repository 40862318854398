import $ from 'jquery';

const Dropdown = (($, ns = '') => {
  const selectors = {
    DROPDOWN      : `.${ns}js-dropdown`,
    ITEM          : `.${ns}dropdown__item`,
    LIST          : `.${ns}dropdown__list`,
    SELECTED      : `.${ns}dropdown__selected`,
    SELECT        : 'select',
    OPTIONS       : 'select option'
  };
  const classes = {
    ACTIVE        : `${ns}dropdown--active`,
    SELECTED      : `${ns}dropdown--selected`,
    ITEM          : `${ns}dropdown__item`,
    ITEM_ACTIVE   : `${ns}dropdown__item--active`
  };

  class Dropdown {
    constructor(el) {
      this.$el = $(el);
      this.$select = this.$el.find(selectors.SELECT);
      this._isShown = false;

      this.render();
      this.update(this.$select.val());

      this.$select.change((e) => {
        this.update($(e.currentTarget).val());
      });

      $(selectors.SELECTED).click(() => {
        this.toggle();
      });

      $(selectors.ITEM).click((e) => {
        this.$select.val($(e.currentTarget).data('value')).change();
        this.hide();
      });
    }

    toggle() {
      return this._isShown ? this.hide() : this.show();
    }

    show() {
      this.$el.addClass(classes.ACTIVE);
      this._isShown = true;
    }

    hide() {
      this.$el.removeClass(classes.ACTIVE);
      this._isShown = false;
    }

    render() {
      this.$el.find(selectors.OPTIONS).each((i, el) => {
        const $option = $(el);
        const $item = $('<li></li>');

        if (!$option.val()) {
          return;
        }

        $item.addClass(classes.ITEM);
        $item.data('value', $option.val());
        $item.text($option.text());
        this.$el.find(selectors.LIST).append($item);
      });
    }

    update(value) {
      const $selected = this.$el.find(selectors.SELECTED);
      const $items = this.$el.find(selectors.ITEM);

      if (!value) {
        $selected.text($selected.data('placeholder'));
        this.$el.removeClass(classes.SELECTED);
        return;
      }

      let selectedText;

      $items.each(function () {
        const $item = $(this);

        $item.removeClass(classes.ITEM_ACTIVE);

        if (value === $item.data('value')) {
          $item.addClass(classes.ITEM_ACTIVE);
          selectedText = $item.text();
        }
      });

      $selected.text(selectedText);
      this.$el.addClass(classes.SELECTED);
    }
  }

  $(document).ready(() => {
    const dds = [];

    $(selectors.DROPDOWN).each(function () {
      dds.push(new Dropdown(this));
    });

    $(document).click((e) => {
      if ($(e.target).parents(selectors.DROPDOWN).length === 0) {
        dds.forEach((dd) => dd.hide());
      }
    });
  });

  return Dropdown;
})($, window.wdkNamespace);

export default Dropdown;
