import $ from 'jquery';

const Tabs = (($, ns = '') => {
  $(document).ready(() => {
    const selectors = {
      TABS: `.${ns}js-tabs`,
      ITEM: `.${ns}tabs__item`,
      CONTENT: `.${ns}tab-content`
    };
    const classes = {
      ITEM_ACTIVE: `${ns}tabs__item--active`,
      CONTENT_ACTIVE: `${ns}tab-content--active`
    };

    $(selectors.TABS).each((i, el) => {
      const $container = $(el);
      const $items = $container.find(selectors.ITEM);

      $items.click(function () {
        const $el = $(this);
        const tab = $(this).data('toggle');

        if (!tab) {
          return;
        }

        $items.removeClass(classes.ITEM_ACTIVE);
        $(selectors.CONTENT).removeClass(classes.CONTENT_ACTIVE);

        $el.addClass(classes.ITEM_ACTIVE);
        $(`.${ns}tab-content[data-toggle=${tab}]`).addClass(classes.CONTENT_ACTIVE);
      });
    });
  });

  return Tabs;
})($, window.wdkNamespace);

export default Tabs;
