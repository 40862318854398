import $ from 'jquery';

const OptionList = (($, ns = '') => {
  const selectors = {
    OPTION_LIST: `.${ns}js-option-list`,
    ITEM: `.${ns}option-list__item`,
    INPUT: 'input',
    INPUT_CHECKED: 'input:checked'
  };
  const classes = {
    ITEM_ACTIVE: `${ns}option-list__item--active`
  };

  $(document).ready(() => {
    $(selectors.OPTION_LIST).each((i, el) => {
      const $container = $(el);
      const $items = $container.find(selectors.ITEM);

      $items.find(selectors.INPUT_CHECKED).each(function () {
        $(this).closest(selectors.ITEM).addClass(classes.ITEM_ACTIVE);
      });

      $items.click(function (e) {
        const $el = $(this);

        if (e.target.tagName.toLowerCase() !== 'input') {
          $el.find(selectors.INPUT).click();
        }

        $el.siblings().addBack().each(function () {
          const $el = $(this);

          if ($el.find(selectors.INPUT).prop('checked')) {
            $el.addClass(classes.ITEM_ACTIVE);
          } else {
            $el.removeClass(classes.ITEM_ACTIVE);
          }
        });
      });
    });
  });

  return OptionList;
})($, window.wdkNamespace);

export default OptionList;
