import $ from 'jquery';

const Form = (($, ns = '') => {
  const selectors = {
    FORM: `form.${ns}js-needs-validation`
  };
  const classes = {
    VALIDATED: `${ns}was-validated`
  };

  $(document).ready(() => {
    $(selectors.FORM).on('submit', function (event) {
      if (this.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }

      $(this).addClass(classes.VALIDATED);
    });
  });

  return Form;
})($, window.wdkNamespace);

export default Form;
