import $ from 'jquery';
import ColorPicker from './color-picker';
import Dropdown from './dropdown';
import Form from './form';
import Modal from './modal';
import OptionList from './option-list';
import Progress from './progress';
import Slider from './slider';
import Tabs from './tabs';

(($) => {
  if (typeof $ === 'undefined') {
    throw new TypeError('Holmgrens\'s JavaScript requires jQuery. jQuery must be included before Holmgrens\'s JavaScript.');
  }

  // eslint-disable-next-line
  const buildNamespace = BUILD_NS;

  if (buildNamespace) {
    window.wdkNamespace = buildNamespace;
  }
})($);

export {
  Form,
  Progress,
  OptionList,
  ColorPicker,
  Dropdown,
  Slider,
  Tabs,
  Modal
};
